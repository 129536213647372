<script lang="ts" setup>
import type { ContentSummary } from "@/types";
const { blok } = defineProps<{ blok: ContentSummary }>();

const firstColumn = computed(() => {
  return blok.links.slice(0, 2);
});

const secondColumn = computed(() => {
  return blok.links.slice(2);
});
</script>

<template>
  <Container
    v-editable="blok"
    class="lg:py-20 py-2.5"
    :style="{ backgroundColor: blok.backgroundColour || 'bg-white' }"
  >
    <div class="flex flex-col gap-5 lg:gap-20">
      <div class="grid lg:grid-cols-4">
        <div
          class="flex flex-col py-5 col-start-2 col-span-2 lg:text-center gap-5"
        >
          <Heading :heading="blok.heading" :subtitle="blok.subtitle" />
          <p class="font-calson text-[19px] lg:text-[21px]">
            {{ blok.description }}
          </p>
        </div>
      </div>

      <div class="hidden md:grid grid-cols-5 lg:gap-[121px] gap-14">
        <div class="flex flex-col gap-[60px] col-span-3">
          <ProductContentSummaryItem
            v-for="(item, index) in firstColumn"
            :key="item._uid"
            :item="item"
            :class="{
              '2xl:w-[505px] xl:w-[440px] lg:w-[370px] md:w-[265px] md:self-end':
                index === firstColumn.length - 1,
            }"
          />
        </div>

        <div class="flex flex-col gap-[120px] col-span-2">
          <ProductContentSummaryItem
            v-for="item in secondColumn"
            :key="item._uid"
            :item="item"
          />
        </div>
      </div>

      <div class="md:hidden">
        <Swiper
          slides-per-view="auto"
          :space-between="20"
          class="sm:!mx-0 !-mx-2.5"
        >
          <SwiperSlide
            v-for="item in blok.links"
            :key="item._uid"
            class="flex items-start !w-auto max-w-full"
          >
            <div class="flex flex-col gap-6">
              <div class="relative">
                <Image
                  :blok="{ image: item.mobileImage, ratio: '4x3' }"
                  class="h-[240px] justify-end flex"
                >
                  <div v-if="item.product" class="p-2.5">
                    <FavoriteButton :product-uuid="item.product" />
                  </div>
                </Image>
              </div>

              <div class="flex flex-col gap-4">
                <BlokLink :blok="item.url" class="flex justify-between">
                  <span
                    class="font-helvetica-bold max-w-[320px] md:max-w-fit"
                    >{{ item.heading }}</span
                  >
                  <img src="/icon/chevron-right-rounded.svg" class="hidden" />
                </BlokLink>
                <p class="w-[300px]">{{ item.description }}</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div class="flex justify-center py-5">
        <BlokLink
          v-for="button in blok.button"
          :key="button._uid"
          v-editable="button"
          :blok="button.url"
          :is-form="button.isForm"
          class="px-10 py-5 rounded-full bg-black text-white"
        >
          {{ button.text }}
        </BlokLink>
      </div>
    </div>
  </Container>
</template>
