<script lang="ts" setup>
import type { ContentSummaryItem } from "@/types";
const wrapper = ref<HTMLDivElement | null>(null);
const textWidth = ref<string>("w-[350px]");

watch([wrapper], (wrapperValue) => {
  if (wrapperValue[0]) {
    const wrapperWidth = wrapperValue[0]?.offsetWidth;

    if (wrapperWidth < 350) {
      textWidth.value = "pr-5";
    }
  }
});

defineProps<{ item: ContentSummaryItem }>();
</script>

<template>
  <div ref="wrapper" v-editable="item" class="flex flex-col gap-6">
    <div class="relative">
      <div class="absolute right-0 p-2.5">
        <FavoriteButton v-if="item.product" :product-uuid="item.product" />
      </div>
      <Image :blok="{ image: item.image, ratio: item.ratio }" />
    </div>
    <div class="flex flex-col lg:w-[370px] max-w-full">
      <BlokLink
        :blok="item.url"
        class="flex gap-2.5 text-xl font-helvetica-bold justify-between"
      >
        <span>{{ item.heading }}</span>
        <NuxtImg
          width="24px"
          height="24px"
          src="/icon/chevron-right-rounded.svg"
          alt="Chevron right"
          class="shrink-0"
        />
      </BlokLink>
      <p class="font-calson" :class="textWidth">
        {{ item.description }}
      </p>
    </div>
  </div>
</template>
